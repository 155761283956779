<template>
    <base-scheda models-name="lingue">

        <template #header="{ model }">
            <scheda-lingua-header :model="model"></scheda-lingua-header>
        </template>

        <template #titolo="{ model }">
            <scheda-lingua-titolo :model="model"></scheda-lingua-titolo>
        </template>

        <template #generale="{ model }">
            <scheda-lingua-generale :model="model"></scheda-lingua-generale>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import SchedaLinguaHeader from '@/components/secure/lingue/components/SchedaLinguaHeader';
    import SchedaLinguaTitolo from '@/components/secure/lingue/components/SchedaLinguaTitolo';
    import SchedaLinguaGenerale from '@/components/secure/lingue/components/SchedaLinguaGenerale';

    export default {
        name: 'SchedaLingua',
        components: {SchedaLinguaGenerale, SchedaLinguaTitolo, SchedaLinguaHeader, BaseScheda}
    }
</script>

<style scoped>

</style>
