<template>
    <base-scheda-titolo models-name="lingue"
                        :model="model"
                        model-ref="la lingua"
                        :titolo="model.NOME"
                        :can-edit="$store.getters.isSystem"
                        :can-delete="$store.getters.isSystem"
                        check-text="Elimina anche tutti i testi associati"
    ></base-scheda-titolo>
</template>

<script>
    import BaseSchedaTitolo from '@/components/aaaProject/scheda/BaseSchedaTitolo';

    export default {
        name: 'SchedaLinguaTitolo',
        components: {BaseSchedaTitolo},
        props: {model: Object}
    }
</script>

<style scoped>

</style>
