<template>
    <base-scheda-generale :model="model" :items="items">

        <template #default="{ item }">
            <button-tooltip class="ml-1"
                            text="DEFAULT"
                            :color="item.data ? 'success' : 'error'"
                            :disabled="!!isGlobalLoading"
                            :loading="isLoading"
                            :tooltip="tooltip"
                            @click.stop="onEnableClick"
            ></button-tooltip>
            <conferma-dialog :dialog="dialog"
                             v-if="dialog"
                             :text="'Impostando ' + model.NOME.toUpperCase() + ' come lingua di default ' +
                                    'disattiverai tipologie ed eventi che non possiedono questa lingua nei loro testi'"
                             @close="dialog = false"
                             @confermato="onEnableClick"
            ></conferma-dialog>
        </template>

    </base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import ConfermaDialog from '@/components/aaaGenerics/commons/ConfermaDialog';

    export default {
        name: 'SchedaLinguaGenerale',
        components: {ButtonTooltip, ConfermaDialog, BaseSchedaGenerale},
        props: {model: Object},
        data: () => ({
            dialog: false,
            apiTimer: null,
            isLoading: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            items: function () {
                return [
                    {label: 'Nome', data: this.model.NOME},
                    {label: 'Sigla', data: this.model.SIGLA},
                    {label: 'Testi Tipologie', data: this.model.TPT_COUNT},
                    {label: 'Testi Evento', data: this.model.EVT_COUNT},
                    {label: 'Default', data: this.model.IS_DEFAULT},
                    {label: 'Visualizzazioni', data: this.model.VISUALIZZAZIONI}
                ]
            },
            tooltip: function () {
                return this.model.IS_DEFAULT
                       ? 'La lingua è impostata come lingua di default'
                       : (this.$store.getters.isSystem
                          ? 'Click per impostare ' + this.model.NOME + ' come lingua di default'
                          : 'La lingua NON è impostata come lingua di default');
            }
        },
        methods: {
            onEnableClick() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //PUT del default della lingua
                        _this.$store.dispatch('defaultRequest', {
                            api: 'PUT_LINGUA_DEFAULT',
                            paylod: _this.model.ID_REC,
                            doResponse: lingua => {
                                _this.model.IS_DEFAULT = lingua.IS_DEFAULT;
                                _this.model.MODIFIER = lingua.MODIFIER;
                                _this.model.MODIFIER_TIMESTAMP = lingua.MODIFIER_TIMESTAMP;
                            },
                            doClose: () => {
                                _this.isLoading = false;
                                _this.dialog = false;
                            }
                        });
                    }
                }

                if (!this.model.IS_DEFAULT && this.$store.getters.isSystem) {

                    if (!this.dialog) {
                        return this.dialog = true;
                    }

                    this.isLoading = true;
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);
                }

            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
